<template>
  <div class="relative flex items-center">
    <Datepicker
      input-class="w-full rounded-lg form-input focus:outline-none"
      wrapper-class="w-full"
      calendar-class="w-full text-sm"
      :disabled-dates="disabledDates"
      :placeholder="placeholder"
      :format="formattedDate"
      :value="value"
      @selected="$emit('selected', $event)"
    />
    <button
      v-if="value"
      class="absolute right-0 flex items-baseline flex-shrink-0 mr-4 text-xs text-right text-gray-600 focus:outline-none"
      @click="clearInput"
    >
      <Icon
        view-box="0 0 14 14"
        class="w-2 h-2 mr-1 transform rotate-45"
      >
        <Plus />
      </Icon>
      <span>Clear</span>
    </button>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import Icon from '@components/Icons/Icon'
import Plus from '@components/Icons/Plus'

import { formatDate } from '@utils/formatDate'

export default {
  components: {
    Datepicker,
    Icon,
    Plus
  },

  props: {
    placeholder: {
      type: String,
      default: null
    },
    value: {
      type: Date,
      default: null
    },
    disablePastDates: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    disabledDates() {
      if (!this.disablePastDates) {
        return null
      }
      return {
        disabledDates: {
          to: new Date() // Disable all dates before today
        }
      }
    }

  },

  methods: {
    formattedDate(date) {
      return formatDate(date)
    },

    clearInput() {
      this.$emit('clear')
    }
  }
}
</script>
